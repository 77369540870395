<template>
  <div>
    <div v-if="loadingQr">
      Loading...
    </div>
    <div v-else>
      <div v-if="qrCode && secret">
        <div v-if="!hide">
          <base-button type="submit"
                       class="submit-button"
                       color="primary"
                       size="big"
                       :loading="loading"
                       @click="toggleHide">Hide</base-button>
          <h4>QR code</h4>
          <p>Scan the QR code with your google authenticator app or use the secret code below.</p>
          <div v-html="qrCode"></div>
          <p style="margin-bottom: 10px">Secret key: {{secret}}</p>

          <base-button type="submit"
                       class="submit-button"
                       color="danger"
                       size="big"
                       :loading="loading"
                       @click="disableTfa">Disable</base-button>

          <br>
          <br>
          <div v-if="!user.tfa">
            Activate two factor authentication
          </div>
          <div v-else>
            Validate the two factor authentication code
          </div>
          <div class="testTfa" v-if="!user.tfa">
            <base-textbox v-model="oneTimeCode"
                          ref="confirmation"
                          placeholder="Type your code" />
            <base-button type="submit"
                         color="primary"
                         class="add"
                         :loading="loadingTest"
                         @click="validateAndActivateTfa">Activate</base-button>
          </div>
          <div class="testTfa" v-else>
            <base-textbox v-model="oneTimeCode"
                          ref="confirmation"
                          placeholder="Type your code" />
            <base-button type="submit"
                         color="primary"
                         class="add"
                         :loading="loadingTest"
                         @click="testTfa">Test</base-button>
          </div>
        </div>
        <div v-else>
          <base-button type="submit"
                       class="submit-button"
                       color="primary"
                       size="big"
                       :loading="loading"
                       @click="toggleHide">Show</base-button>
        </div>
      </div>
      <div v-else>
        You can enable two factor authentication for your account.
        <base-button type="submit"
                     class="submit-button"
                     color="primary"
                     size="big"
                     :loading="loading"
                     @click="enableTfa">Enable</base-button>
      </div>
    </div>
    <br>
    <div v-if="!hide">
      <base-alert v-if="success"
                  class="alert-login-success"
                  type="success">{{ success }}</base-alert>
      <base-alert v-if="error"
                  class="alert-login-error"
                  type="error">{{ error }}</base-alert>
    </div>
  </div>
</template>

<script>
import auth from '@/services/auth'
import usersApi from '@/api/usersApi'

export default {
  components: {
  },

  data () {
    return {
      qrCode: null,
      secret: null,
      loading: false,
      loadingQr: true, // Added state to track QR loading
      loadingTest: false,
      error: null,
      oneTimeCode: null,
      success: null,
      hide: true
    }
  },

  created () {
    this.getQr()
  },

  methods: {
    async getQr () {
      try {
        const response = await auth.getQr()
        this.qrCode = response.image
        this.secret = response.secret
      } catch (e) {
        this.error = 'Something went wrong.'
      } finally {
        this.loadingQr = false // Set loading to false after fetching
      }
    },
    async enableTfa () {
      this.loadingQr = true // Set loading to true before fetching
      this.success = false
      this.error = false
      try {
        const response = await auth.enableTfa()
        this.qrCode = response.image
        this.secret = response.secret
        this.hide = false
        await this.refreshCurrentUser()
      } catch (e) {
        this.error = 'Something went wrong.'
      } finally {
        this.loadingQr = false // Set loading to false after fetching
      }
    },
    async disableTfa () {
      this.loading = true
      this.success = false
      this.error = false
      try {
        await auth.disableTfa()
        this.qrCode = null
        this.secret = null
        await this.refreshCurrentUser()
      } catch (e) {
        this.error = 'Something went wrong.'
      } finally {
        this.success = false
        this.error = false
        this.loading = false
      }
    },

    async testTfa () {
      this.loadingTest = true
      try {
        const response = await usersApi.testOneTimeCode(this.oneTimeCode)
        if (response.success) {
          this.success = 'The code is valid'
          this.error = false
        } else {
          this.error = 'The code is not valid'
          this.success = false
        }
      } catch (e) {
        this.error = 'Something went wrong.'
      } finally {
        this.loadingTest = false
      }
    },

    async validateAndActivateTfa () {
      this.loadingTest = true
      try {
        const response = await usersApi.validateAndActivateTfa(this.oneTimeCode, this.secret)
        if (response.success) {
          this.success = 'Activation complete'
          this.error = false
          await this.refreshCurrentUser()
        } else {
          this.error = 'There was an error activating the two factor authentication'
          this.success = false
        }
      } catch (e) {
        this.error = 'Something went wrong.'
      } finally {
        this.loadingTest = false
      }
    },

    async toggleHide () {
      this.hide = !this.hide
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])

  },
  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script >
<style lang="scss" scoped>
.testTfa {
  display: flex;
  width: 25%;

  .base-text-box {
    flex-grow: 4;
    margin: 0;
  }

  .add {
    margin: 0 0 0 10px;
  }
}
</style>
